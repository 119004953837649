const togglemenubutton = (elem, e) => {
    if (!elem) return;
    e.preventDefault();
    e.stopPropagation();
    let state = 'false';
    if (elem.getAttribute('aria-expanded') === 'false') {
        state = 'true';
    }
    elem.setAttribute('aria-expanded', state);
}

const menubutton = (selector) => {
    if (!selector) return;
    let buttons = document.querySelectorAll(selector);
    buttons.forEach((button) => {
        button.removeAttribute('hidden');
        button.addEventListener('click', togglemenubutton.bind(window, button));
    });
};

menubutton('.hamburger');